import { useState } from 'react';
import { Loader, Plus, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import Layout from '../components/layout';
import Modal from '../components/shared/Modal';
import PlayersTable from '../components/players/PlayersTable';
import useAuth from '../hooks/useAuth';
import UpdatePlayerRequest from '../models/player/UpdatePlayerRequest';
import playerService from '../services/PlayerService';

export default function Players() {
    const [playerName, setPlayerName] = useState('');
    const [socialAccount, setSocialAccount] = useState('');
    const [playerLevel, setPlayerLevel] = useState('');
    const [missionLevel, setMissionLevel] = useState('');
    const [coins, setCoins] = useState('');

    const [addPlayerShow, setAddPlayerShow] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(50); // Default page size of 10

    const { data, isLoading } = useQuery(
        ['players', playerName, socialAccount, playerLevel, missionLevel, coins, currentPage],
        async () => {
            return await playerService.findAll(currentPage, pageSize);
        },
        {
            refetchInterval: 1000,
        }
    );

    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
        reset,
    } = useForm<UpdatePlayerRequest>();

    const savePlayer = async (updatePlayerRequest: UpdatePlayerRequest) => {
        try {
            await playerService.save(updatePlayerRequest);
            setAddPlayerShow(false);
            setError(null);
            reset();
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    return (
        <Layout>
            <h1 className="font-semibold text-3xl mb-5">Manage Players</h1>
            <hr />
            <button
                className="btn my-5 flex gap-2 w-full sm:w-auto justify-center"
                onClick={() => setAddPlayerShow(true)}
            >
                <Plus /> Add Player
            </button>

            <div className="table-filter mt-2">
                {/* <div className="flex flex-row gap-5">
          <input
            type="text"
            className="input w-1/2"
            placeholder="Player Name"
            value={playerName}
            onChange={(e) => setPlayerName(e.target.value)}
          />
          <input
            type="text"
            className="input w-1/2"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div> */}
                <div className="flex flex-row gap-5">
                    <input
                        type="text"
                        className="input w-1/2"
                        placeholder="Player Name"
                        value={playerName}
                        onChange={(e) => setPlayerName(e.target.value)}
                    />
                    <input
                        type="text"
                        className="input w-1/2"
                        placeholder="Social Account"
                        value={socialAccount}
                        onChange={(e) => setSocialAccount(e.target.value)}
                    />
                    <input
                        type="text"
                        className="input w-1/2"
                        placeholder="Player Level"
                        value={playerLevel}
                        onChange={(e) => setPlayerLevel(e.target.value)}
                    />
                    <input
                        type="text"
                        className="input w-1/2"
                        placeholder="Mission Level"
                        value={missionLevel}
                        onChange={(e) => setMissionLevel(e.target.value)}
                    />
                    <input
                        type="text"
                        className="input w-1/2"
                        placeholder="Coins"
                        value={coins}
                        onChange={(e) => setCoins(e.target.value)}
                    />
                </div>
            </div>

            <PlayersTable
                data={data?.data}
                isLoading={isLoading}
                total={data?.total}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
            />

            {/* Add Player Modal */}
            <Modal show={addPlayerShow}>
                <div className="flex">
                    <h1 className="font-semibold mb-3">Add Player</h1>
                    <button
                        className="ml-auto focus:outline-none"
                        onClick={() => {
                            reset();
                            setError(null);
                            setAddPlayerShow(false);
                        }}
                    >
                        <X size={30} />
                    </button>
                </div>
                <hr />

                <form className="flex flex-col gap-5 mt-5" onSubmit={handleSubmit(savePlayer)}>
                    <input
                        type="text"
                        className="input sm:w-1/2"
                        placeholder="PlayerName"
                        required
                        disabled={isSubmitting}
                        {...register('playerName')}
                    />
                    <input
                        type="text"
                        className="input sm:w-1/2"
                        placeholder="Social Account"
                        required
                        disabled={isSubmitting}
                        {...register('socialAccount')}
                    />
                    <input
                        type="text"
                        className="input sm:w-1/2"
                        placeholder="Player Level"
                        required
                        disabled={isSubmitting}
                        {...register('playerLevel')}
                    />
                    <input
                        type="text"
                        className="input sm:w-1/2"
                        placeholder="Mission Level"
                        required
                        disabled={isSubmitting}
                        {...register('missionLevel')}
                    />
                    <input
                        type="text"
                        className="input sm:w-1/2"
                        placeholder="Coins"
                        required
                        disabled={isSubmitting}
                        {...register('coins')}
                    />

                    <button className="btn" disabled={isSubmitting}>
                        {isSubmitting ? <Loader className="animate-spin mx-auto" /> : 'Save'}
                    </button>
                    {error ? (
                        <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">{error}</div>
                    ) : null}
                </form>
            </Modal>
        </Layout>
    );
}
