import '../../styles/missions.css';

import { useEffect, useState } from 'react';
import { AlertTriangle, Loader, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { UnityContext } from 'react-unity-webgl';

import Mission from '../../models/mission/Mission';
import UpdateMissionRequest from '../../models/mission/UpdateMissionRequest';
import missionService from '../../services/MissionService';
import Modal from '../shared/Modal';
import Table from '../shared/Table';
import TableItem from '../shared/TableItem';
interface MissionsTableProps {
    data: Mission[];
    isLoading: boolean;
    total: number; // Add total number of items
    pageSize: number; // Number of items per page
    currentPage: number; // Current page number
    onPageChange: (page: number) => void; // Function to handle page changes
}

export const unityContext = new UnityContext({
    loaderUrl: 'Build/dpboardeditor23091645.loader.js',
    dataUrl: 'Build/dpboardeditor23091645.data',
    frameworkUrl: 'Build/dpboardeditor23091645.framework.js',
    codeUrl: 'Build/dpboardeditor23091645.wasm',
});
export function MissionsTable({ data, isLoading, total, pageSize, currentPage, onPageChange }: MissionsTableProps) {
    const [deleteShow, setDeleteShow] = useState<boolean>(false);
    const [updateShow, setUpdateShow] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [selectedMissionId, setSelectedMissionId] = useState<string>();
    const [error, setError] = useState<string>();

    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
        reset,
        setValue,
    } = useForm<UpdateMissionRequest>();

    const handleDelete = async () => {
        try {
            setIsDeleting(true);
            await missionService.delete(selectedMissionId);
            setDeleteShow(false);
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleUpdate = async (updateMissionRequest: UpdateMissionRequest) => {
        try {
            await missionService.update(updateMissionRequest);
            setUpdateShow(false);
            reset();
            setError(null);
        } catch (error) {
            setError(error.response.data.message);
        }
    };
    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
            onPageChange(newPage);
        }
    };

    function updateUnityBoard(
        level,
        gridWidth,
        gridHeight,
        collects,
        boosters,
        scores,
        moves,
        buyIn,
        levelReward,
        firstGrid,
        firstFruitGrid,
        fallDown,
        specialTilesGrid,
        layer2IsOn
    ) {
        if (boosters == null) {
            boosters = '0,0,0,0,';
        }
        unityContext.send('UIManager', 'SetLevel', level);
        unityContext.send('UIManager', 'SetGridWidth', gridWidth);
        unityContext.send('UIManager', 'SetGridHeight', gridHeight);
        unityContext.send('UIManager', 'SetCollects', collects);
        unityContext.send('UIManager', 'SetBoosters', boosters);
        unityContext.send('UIManager', 'SetScores', scores);
        unityContext.send('UIManager', 'SetMoves', moves);
        unityContext.send('UIManager', 'SetBuyIn', buyIn);
        unityContext.send('UIManager', 'SetLevelReward', levelReward);
        unityContext.send('UIManager', 'SetFirstGrid', firstGrid);
        unityContext.send('UIManager', 'SetFirstFruitGrid', firstFruitGrid);
        unityContext.send('UIManager', 'SetFallDown', fallDown);
        unityContext.send('UIManager', 'SetSpecialDomino', specialTilesGrid);
        unityContext.send('UIManager', 'SetLayer2', layer2IsOn);
        console.log('log on unity', layer2IsOn);
    }

    useEffect(() => {
        unityContext.on(
            'Save',
            function (
                _level,
                _width,
                _height,
                _collects,
                _boosters,
                _scores,
                _moves,
                _buyin,
                _levelReward,
                _firstGrid,
                _firstFruitGrid,
                _fallDown,
                _specialTilesGrid,
                _layer2IsOn
            ) {
                const updateMissionRequest: UpdateMissionRequest = {
                    level: _level,
                    gridWidth: _width,
                    gridHeight: _height,
                    collects: _collects,
                    boosters: _boosters,
                    scores: _scores,
                    moves: _moves,
                    buyIn: _buyin,
                    levelReward: _levelReward,
                    firstGrid: _firstGrid,
                    firstFruitGrid: _firstFruitGrid,
                    fallDown: _fallDown,
                    specialTilesGrid: _specialTilesGrid,
                    layer2IsOn: _layer2IsOn,
                };
                console.log('log on table');
                console.log('updateMission Request', updateMissionRequest);
                handleUpdate(updateMissionRequest);
            }
        );
    }, []);
    useEffect(() => {
        unityContext.on('EditMissionFromUnity', async function (level: string) {
            // Try to find the mission in the local data first
            const mission = data.find((mission) => mission.level === level);

            if (mission) {
                // If found, proceed to update the Unity board and show the modal
                setSelectedMissionId(mission.level);
                updateUnityBoard(
                    mission.level,
                    mission.gridWidth,
                    mission.gridHeight,
                    mission.collects,
                    mission.boosters,
                    mission.scores,
                    mission.moves,
                    mission.buyIn,
                    mission.levelReward,
                    mission.firstGrid,
                    mission.firstFruitGrid,
                    mission.fallDown,
                    mission.specialTilesGrid,
                    mission.layer2IsOn
                );
                setUpdateShow(true); // Open the edit modal
            } else {
                // If not found, call the findOne method
                try {
                    const fetchedMission = await missionService.findOne(level);

                    if (fetchedMission) {
                        // Update Unity board and show the modal if mission is found
                        setSelectedMissionId(fetchedMission.level);
                        updateUnityBoard(
                            fetchedMission.level,
                            fetchedMission.gridWidth,
                            fetchedMission.gridHeight,
                            fetchedMission.collects,
                            fetchedMission.boosters,
                            fetchedMission.scores,
                            fetchedMission.moves,
                            fetchedMission.buyIn,
                            fetchedMission.levelReward,
                            fetchedMission.firstGrid,
                            fetchedMission.firstFruitGrid,
                            fetchedMission.fallDown,
                            fetchedMission.specialTilesGrid,
                            fetchedMission.layer2IsOn
                        );
                        setUpdateShow(true); // Open the edit modal
                    } else {
                        console.error('Mission not found for level:', level);
                    }
                } catch (error) {
                    console.error('Error fetching mission:', error);
                }
            }
        });
    }, [data]); // Include data in the dependency array
    return (
        <>
            <div className="table-container">
                <Table
                    columns={[
                        'Level',
                        'Grid Width',
                        'Grid Height',
                        'Collects',
                        'Boosters',
                        'Scores',
                        'Moves',
                        'BuyIn Amount',
                        'Level Reward',
                    ]}
                >
                    {isLoading
                        ? null
                        : data
                              .sort((a: Mission, b: Mission) => {
                                  if (parseInt(a.level) < parseInt(b.level)) {
                                      return -1;
                                  }
                                  if (parseInt(a.level) > parseInt(b.level)) {
                                      return 1;
                                  }
                                  return 0;
                              })
                              .map(
                                  ({
                                      id,
                                      level,
                                      gridWidth,
                                      gridHeight,
                                      collects,
                                      boosters,
                                      scores,
                                      moves,
                                      buyIn,
                                      levelReward,
                                      firstGrid,
                                      firstFruitGrid,
                                      fallDown,
                                      specialTilesGrid,
                                      layer2IsOn,
                                  }) => {
                                      return (
                                          <tr key={id}>
                                              <TableItem>{level}</TableItem>
                                              <TableItem>{gridWidth}</TableItem>
                                              <TableItem>{gridHeight}</TableItem>
                                              <TableItem>{collects}</TableItem>
                                              <TableItem>{boosters}</TableItem>
                                              <TableItem>{scores}</TableItem>
                                              <TableItem>{moves}</TableItem>
                                              <TableItem>{buyIn}</TableItem>
                                              <TableItem>{levelReward}</TableItem>
                                              <TableItem className="text-right">
                                                  <button
                                                      className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                                                      onClick={() => {
                                                          setSelectedMissionId(level);
                                                          updateUnityBoard(
                                                              level,
                                                              gridWidth,
                                                              gridHeight,
                                                              collects,
                                                              boosters,
                                                              scores,
                                                              moves,
                                                              buyIn,
                                                              levelReward,
                                                              firstGrid,
                                                              firstFruitGrid,
                                                              fallDown,
                                                              specialTilesGrid,
                                                              layer2IsOn
                                                          );
                                                          setUpdateShow(true);
                                                      }}
                                                  >
                                                      Edit
                                                  </button>
                                                  <button
                                                      className="text-red-600 hover:text-red-900 ml-3 focus:outline-none"
                                                      onClick={() => {
                                                          setSelectedMissionId(level);
                                                          setDeleteShow(true);
                                                      }}
                                                  >
                                                      Delete
                                                  </button>
                                              </TableItem>
                                          </tr>
                                      );
                                  }
                              )}
                </Table>

                {!isLoading && data.length < 1 ? (
                    <div className="text-center my-5 text-gray-500">
                        <h1>Empty</h1>
                    </div>
                ) : null}
                <div className="flex items-center justify-between my-4 px-4">
                    <button
                        className={`btn ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''} px-4 py-2`}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className="text-gray-700 mx-4">
                        Page {currentPage} of {Math.ceil(total / pageSize)}
                    </span>
                    <button
                        className={`btn ${
                            currentPage === Math.ceil(total / pageSize) ? 'opacity-50 cursor-not-allowed' : ''
                        } px-4 py-2`}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === Math.ceil(total / pageSize)}
                    >
                        Next
                    </button>
                </div>
            </div>
            {/* Delete Mission Modal */}
            <Modal show={deleteShow}>
                <AlertTriangle size={30} className="text-red-500 mr-5 fixed" />
                <div className="ml-10">
                    <h3 className="mb-2 font-semibold">Delete Mission</h3>
                    <hr />
                    <p className="mt-2">
                        Are you sure you want to delete the Mission? All of Mission's data will be permanently removed.
                        <br />
                        This action cannot be undone.
                    </p>
                </div>
                <div className="flex flex-row gap-3 justify-end mt-5">
                    <button
                        className="btn"
                        onClick={() => {
                            setError(null);
                            setDeleteShow(false);
                        }}
                        disabled={isDeleting}
                    >
                        Cancel
                    </button>
                    <button className="btn danger" onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loader className="mx-auto animate-spin" /> : 'Delete'}
                    </button>
                </div>
                {error ? (
                    <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">{error}</div>
                ) : null}
            </Modal>
        </>
    );
}
