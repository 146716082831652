import { useState } from 'react';
import { AlertTriangle, Loader, X } from 'react-feather';
import { useForm } from 'react-hook-form';

import UpdatePlayerRequest from '../../models/player/UpdatePlayerRequest';
import Player from '../../models/player/Player';
import playerService from '../../services/PlayerService';
import Modal from '../shared/Modal';
import Table from '../shared/Table';
import TableItem from '../shared/TableItem';

interface PlayersTableProps {
    data: Player[];
    isLoading: boolean;
    total: number; // Add total number of items
    pageSize: number; // Number of items per page
    currentPage: number; // Current page number
    onPageChange: (page: number) => void; // Function to handle page changes
}

export default function PlayersTable({
    data,
    isLoading,
    total,
    pageSize,
    currentPage,
    onPageChange,
}: PlayersTableProps) {
    const [deleteShow, setDeleteShow] = useState<boolean>(false);
    const [updateShow, setUpdateShow] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [selectedPlayerName, setSelectedPlayerName] = useState<string>();
    const [error, setError] = useState<string>();

    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
        reset,
        setValue,
    } = useForm<UpdatePlayerRequest>();

    const handleDelete = async () => {
        try {
            setIsDeleting(true);
            await playerService.delete(selectedPlayerName);
            setDeleteShow(false);
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleUpdate = async (updatePlayerRequest: UpdatePlayerRequest) => {
        try {
            await playerService.update(updatePlayerRequest);
            setUpdateShow(false);
            reset();
            setError(null);
        } catch (error) {
            setError(error.response.data.message);
        }
    };
    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
            onPageChange(newPage);
        }
    };

    return (
        <>
            <div className="table-container">
                <Table columns={['Player Name', 'Social Account', 'Player Level', 'Mission Level', 'Coins']}>
                    {isLoading ? (
                        <tr>
                            <td colSpan={5} className="text-center">
                                <Loader className="animate-spin" />
                            </td>
                        </tr>
                    ) : (
                        data.map(({ id, playerName, socialAccount, playerLevel, missionLevel, coins }) => (
                            <tr key={id}>
                                <TableItem>{playerName}</TableItem>
                                <TableItem>{socialAccount}</TableItem>
                                <TableItem>{playerLevel}</TableItem>
                                <TableItem>{missionLevel}</TableItem>
                                <TableItem>{coins}</TableItem>

                                <TableItem className="text-right">
                                    <button
                                        className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                                        onClick={() => {
                                            setSelectedPlayerName(playerName);
                                            setValue('playerName', playerName);
                                            setValue('socialAccount', socialAccount);
                                            setValue('playerLevel', playerLevel);
                                            setValue('missionLevel', missionLevel);
                                            setValue('coins', coins);
                                            setUpdateShow(true);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="text-red-600 hover:text-red-900 ml-3 focus:outline-none"
                                        onClick={() => {
                                            setSelectedPlayerName(playerName);
                                            setDeleteShow(true);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </TableItem>
                            </tr>
                        ))
                    )}
                </Table>

                {!isLoading && data.length < 1 ? (
                    <div className="text-center my-5 text-gray-500">
                        <h1>Empty</h1>
                    </div>
                ) : null}

                <div className="flex items-center justify-between my-4 px-4">
                    <button
                        className={`btn ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''} px-4 py-2`}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className="text-gray-700 mx-4">
                        Page {currentPage} of {Math.ceil(total / pageSize)}
                    </span>
                    <button
                        className={`btn ${
                            currentPage === Math.ceil(total / pageSize) ? 'opacity-50 cursor-not-allowed' : ''
                        } px-4 py-2`}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === Math.ceil(total / pageSize)}
                    >
                        Next
                    </button>
                </div>
            </div>

            {/* Delete Player Modal */}
            <Modal show={deleteShow}>
                <AlertTriangle size={30} className="text-red-500 mr-5 fixed" />
                <div className="ml-10">
                    <h3 className="mb-2 font-semibold">Delete Player</h3>
                    <hr />
                    <p className="mt-2">
                        Are you sure you want to delete the Player? All of Player's data will be permanently removed.
                        <br />
                        This action cannot be undone.
                    </p>
                </div>
                <div className="flex flex-row gap-3 justify-end mt-5">
                    <button
                        className="btn"
                        onClick={() => {
                            setError(null);
                            setDeleteShow(false);
                        }}
                        disabled={isDeleting}
                    >
                        Cancel
                    </button>
                    <button className="btn danger" onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loader className="mx-auto animate-spin" /> : 'Delete'}
                    </button>
                </div>
                {error ? (
                    <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">{error}</div>
                ) : null}
            </Modal>
            {/* Update Player Modal */}
            <Modal show={updateShow}>
                <div className="flex">
                    <h1 className="font-semibold mb-3">Update Player</h1>
                    <button
                        className="ml-auto focus:outline-none"
                        onClick={() => {
                            setUpdateShow(false);
                            setError(null);
                            reset();
                        }}
                    >
                        <X size={30} />
                    </button>
                </div>
                <hr />

                <form className="flex flex-col gap-5 mt-5" onSubmit={handleSubmit(handleUpdate)}>
                    <input
                        type="text"
                        className="input"
                        placeholder="playerName"
                        disabled={isSubmitting}
                        {...register('playerName')}
                    />
                    <input
                        type="text"
                        className="input"
                        placeholder="socialAccount"
                        disabled={isSubmitting}
                        {...register('socialAccount')}
                    />
                    <input
                        type="text"
                        className="input"
                        placeholder="playerLevel"
                        disabled={isSubmitting}
                        {...register('playerLevel')}
                    />
                    <input
                        type="text"
                        className="input"
                        placeholder="missionLevel"
                        disabled={isSubmitting}
                        {...register('missionLevel')}
                    />
                    <input
                        type="text"
                        className="input"
                        placeholder="coins"
                        disabled={isSubmitting}
                        {...register('coins')}
                    />

                    <button className="btn" disabled={isSubmitting}>
                        {isSubmitting ? <Loader className="animate-spin mx-auto" /> : 'Save'}
                    </button>
                    {error ? (
                        <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">{error}</div>
                    ) : null}
                </form>
            </Modal>
        </>
    );
}
