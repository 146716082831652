import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import useAuth from './hooks/useAuth';
import Players from './pages/Players';
import Missions from './pages/Missions';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Users from './pages/Users';
import { AuthRoute, PrivateRoute } from './Route';
import authService from './services/AuthService';

export default function App() {
    const { authenticatedUser, setAuthenticatedUser } = useAuth();
    const [isLoaded, setIsLoaded] = useState(false);

    const authenticate = async () => {
        try {
            const authResponse = await authService.refresh();
            setAuthenticatedUser(authResponse.user);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        if (!authenticatedUser) {
            authenticate();
        } else {
            setIsLoaded(true);
        }
    }, []);

    return isLoaded ? (
        <Router>
            <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/users" component={Users} roles={['admin']} />
                <PrivateRoute exact path="/players" component={Players} />
                <PrivateRoute exact path="/missions" component={Missions} />

                <AuthRoute exact path="/login" component={Login} />
            </Switch>
        </Router>
    ) : null;
}
