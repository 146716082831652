import { useCallback, useEffect, useState } from 'react';
import { Loader, Plus, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import Layout from '../components/layout';
import Modal from '../components/shared/Modal';
import { MissionsTable, unityContext } from '../components/missions/MissionsTable';
import useAuth from '../hooks/useAuth';
import UpdateMissionRequest from '../models/mission/UpdateMissionRequest';
import missionService from '../services/MissionService';
import Unity from 'react-unity-webgl';

export default function Missions() {
    const [level, setLevel] = useState('1');
    const [gridWidth, setGridWidth] = useState('3');
    const [gridHeight, setGridHeight] = useState('5');
    const [collects, setCollects] = useState('2,3,4,0,0,0,');
    const [scores, setScores] = useState('0');
    const [moves, setMoves] = useState('20');
    const [buyIn, setBuyIn] = useState('50');
    const [levelReward, setLevelReward] = useState('100');
    const [firstGrid, setFirstGrid] = useState('');

    const [addMissionShow, setAddMissionShow] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(50); // Default page size of 50

    const { data, isLoading } = useQuery(
        ['missions', level, gridWidth, gridHeight, collects, scores, moves, buyIn, levelReward, firstGrid],
        async () => {
            return await missionService.findAllPage(currentPage, pageSize);
        },
        {
            refetchInterval: 1000,
        }
    );

    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
        reset,
    } = useForm<UpdateMissionRequest>();

    const saveMission = async (updateMissionRequest: UpdateMissionRequest) => {
        try {
            await missionService.save(updateMissionRequest);
            setAddMissionShow(false);
            setError(null);
            reset();
        } catch (error) {
            setError(error.response.data.message);
        }
    };
    useEffect(() => {
        unityContext.on(
            'Save',
            function (
                _level,
                _width,
                _height,
                _collects,
                _boosters,
                _scores,
                _moves,
                _buyin,
                _levelReward,
                _firstGrid,
                _firstFruitGrid,
                _fallDown,
                _specialTilesGrid,
                _layer2IsOn
            ) {
                const updateMissionRequest: UpdateMissionRequest = {
                    level: _level,
                    gridWidth: _width,
                    gridHeight: _height,
                    collects: _collects,
                    boosters: _boosters,
                    scores: _scores,
                    moves: _moves,
                    buyIn: _buyin,
                    levelReward: _levelReward,
                    firstGrid: _firstGrid,
                    firstFruitGrid: _firstFruitGrid,
                    fallDown: _fallDown,
                    specialTilesGrid: _specialTilesGrid,
                    layer2IsOn: _layer2IsOn,
                };
                console.log('updateMission Request', updateMissionRequest);
                saveMission(updateMissionRequest);
            }
        );
    }, []);
    return (
        <Layout>
            <h1 className="font-semibold text-3xl mb-5">Manage Missions</h1>
            <hr />
            <div className="container">
                <Unity className="unity" unityContext={unityContext} />
            </div>
            {/* Add Mission Modal */}
            {/* <Modal show={addMissionShow}>
                <div className="flex">
                    <h1 className="font-semibold mb-3">Add Mission</h1>
                    <button
                        className="ml-auto focus:outline-none"
                        onClick={() => {
                            reset();
                            setError(null);
                            setAddMissionShow(false);
                        }}
                    >
                        <X size={30} />
                    </button>
                </div>
                <hr />
                <form className="flex flex-col gap-5 mt-5" onSubmit={handleSubmit(saveMission)}>
                    <button className="btn" disabled={isSubmitting}>
                        {isSubmitting ? <Loader className="animate-spin mx-auto" /> : 'Save'}
                    </button>
                    {error ? (
                        <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">{error}</div>
                    ) : null}
                </form>
            </Modal> */}

            <MissionsTable
                data={data?.data}
                isLoading={isLoading}
                total={data?.total}
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={setCurrentPage}
            />
        </Layout>
    );
}
