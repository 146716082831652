import UpdateMissionRequest from '../models/mission/UpdateMissionRequest';
import Mission from '../models/mission/Mission';
import apiService from './ApiService';
interface MissionResponse {
    data: Mission[];
    total: number;
}
interface FindMissionDto {
    level: string;
}
class MissionService {
    async save(updateMissionRequest: UpdateMissionRequest): Promise<void> {
        await apiService.post('/api/mission/updateMission', updateMissionRequest);
    }

    async findAll(): Promise<Mission[]> {
        return (await apiService.get<Mission[]>('/api/mission', {})).data;
    }
    async findAllPage(page: number = 1, limit: number = 10): Promise<MissionResponse> {
        const response = await apiService.get<MissionResponse>('/api/mission/paged', {
            params: {
                page,
                limit,
            },
        });
        return response.data;
    }

    async findOne(level: string): Promise<Mission> {
        const response = await apiService.get('/api/mission/findMissionByLevel', {
            params: {
                level: level || undefined,
            },
        });

        return response.data; // Return the data directly
    }

    async update(updateMissionRequest: UpdateMissionRequest): Promise<void> {
        const {
            level,
            gridWidth,
            gridHeight,
            collects,
            scores,
            moves,
            buyIn,
            levelReward,
            firstGrid,
            firstFruitGrid,
            specialTilesGrid,
        } = updateMissionRequest;
        await apiService.post(`/api/mission/updateMission`, {
            level: level || undefined,
            gridWidth: gridWidth || undefined,
            gridHeight: gridHeight || undefined,
            collects: collects || undefined,
            scores: scores || undefined,
            moves: moves || undefined,
            buyIn: buyIn || undefined,
            levelReward: levelReward || undefined,
            firstGrid: firstGrid || undefined,
            firstFruitGrid: firstFruitGrid || undefined,
            specialTilesGrid: specialTilesGrid || undefined,
        });
    }

    async delete(level: string): Promise<void> {
        await apiService.post(`/api/mission/deleteMission`, {
            level: level || undefined,
        });
    }
}

export default new MissionService();
