import UpdatePlayerRequest from '../models/player/UpdatePlayerRequest';
import Player from '../models/player/Player';
import apiService from './ApiService';
import PlayerResponse from '../models/player/PlayerResponse';

class PlayerService {
    async save(updatePlayerRequest: UpdatePlayerRequest): Promise<void> {
        console.log(updatePlayerRequest);
        await apiService.post('/api/player/updatePlayer', updatePlayerRequest);
    }

    async findAll(page: number = 1, limit: number = 10): Promise<PlayerResponse> {
        const response = await apiService.get<PlayerResponse>('/api/player', {
            params: {
                page,
                limit,
            },
        });
        return response.data;
    }

    async findOne(playerName: string): Promise<Player> {
        return (await apiService.get<Player>(`/api/player/findPlayerByName`)).data;
    }

    async update(updatePlayerRequest: UpdatePlayerRequest): Promise<void> {
        const { playerName, socialAccount, playerLevel, missionLevel, coins } = updatePlayerRequest;
        await apiService.post(`/api/player/updatePlayer`, {
            playerName: playerName || undefined,
            socialAccount: socialAccount || undefined,
            playerLevel: playerLevel || undefined,
            missionLevel: missionLevel || undefined,
            coins: coins || undefined,
        });
    }

    async delete(name: string): Promise<void> {
        await apiService.post(`/api/player/deletePlayer`, {
            playerName: name || undefined,
        });
    }
}

export default new PlayerService();
