import { BookOpen, Home, LogOut, Users } from 'react-feather';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import authService from '../../services/AuthService';
import SidebarItem from './SidebarItem';

interface SidebarProps {
    className: string;
}

export default function Sidebar({ className }: SidebarProps) {
    const history = useHistory();

    const { authenticatedUser, setAuthenticatedUser } = useAuth();

    const handleLogout = async () => {
        await authService.logout();
        setAuthenticatedUser(null);
        history.push('/login');
    };

    return (
        <div className={'sidebar ' + className}>
            <Link to="/" className="no-underline text-black">
                <h1 className="font-semibold text-center">DominoPuzzle Admin</h1>
            </Link>
            <nav className="mt-5 flex flex-col gap-3 flex-grow">
                <SidebarItem to="/">
                    <Home /> Dashboard
                </SidebarItem>
                {authenticatedUser.role === 'admin' ? (
                    <SidebarItem to="/missions">
                        <Home /> Missions
                    </SidebarItem>
                ) : null}
                <SidebarItem to="/leaderboard">
                    <BookOpen /> Leaderboard
                </SidebarItem>
                <SidebarItem to="/iaps">
                    <BookOpen /> In App Purchases
                </SidebarItem>
                {authenticatedUser.role === 'admin' ? (
                    <SidebarItem to="/players">
                        <Users /> Players
                    </SidebarItem>
                ) : null}
                {authenticatedUser.role === 'admin' ? (
                    <SidebarItem to="/users">
                        <Users /> Admins
                    </SidebarItem>
                ) : null}
            </nav>
            <button
                className="text-red-500 rounded-md p-3 transition-colors flex gap-3 justify-center items-center font-semibold focus:outline-none"
                onClick={handleLogout}
            >
                <LogOut /> Logout
            </button>
        </div>
    );
}
